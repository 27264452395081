import React from "react";
import "./Contactus.css";
import contactus from "../Images/contactus.jpg";

import { FaLocationArrow, FaPhoneAlt, FaMailBulk } from "react-icons/fa";

const Contactus = () => {
  return (
    <div className="home-component contact-us-conp">
      <div className="home-image-container">
        <img src={contactus} alt="xxx" />
      </div>
      {/* <div className="text-of-home-page">
        <p> Avcom Network Technologies </p>
      </div> */}
      <div>
        <div className="location-and-phone-box-contact-us">
          <div className="contact-us-each-box">
            <div className="icon-box-contact-us">
              <FaLocationArrow className="contact-us-icon" />
            </div>
            <h5 className="contact-us-box-heading">Our Office</h5> <br />
            <div className="contact-us-text-details">
              Avcom Network Technologies, <br /> Shiv mandir marg, <br /> badli
              110043, <br /> Delhi
            </div>
          </div>

          <div className="contact-us-each-box">
            <div className="icon-box-contact-us">
              <FaPhoneAlt className="contact-us-icon" />
            </div>
            <h5 className="contact-us-box-heading">Phone Number</h5> <br />
            <div className="contact-us-text-details">
              +91 98105 26350 <br /> <br />
              +91 99111 89318
            </div>
          </div>

          <div className="contact-us-each-box">
            <div className="icon-box-contact-us">
              <FaMailBulk className="contact-us-icon" />
            </div>
            <h5 className="contact-us-box-heading">Email</h5> <br />
            <div className="contact-us-text-details">
              avcomnetworktechnologies@gmail.com
            </div>
          </div>
        </div>

        {/* form start here */}
        <div className="form-of-contact-us-form-sec">
          <div className="form-container-inner">
            <form
              action="https://formsubmit.co/avcomnetworktechnologies@gmail.com"
              method="POST"
              className="form-container-of-avcom"
            >
              <h2 className="form-heading-avcom">Contact Form</h2>
              <div className="box-of-form-container-of-grevance-form">
                <input
                  type="text"
                  id="name"
                  name="name"
                  // placeholder="Enter Your Name"
                  required
                  autoComplete="off"
                  placeholder="Name"
                />
              </div>
              <div
                className="box-of-form-container-of-grevance-form"
                style={{ textTransform: "lowercase" }}
              >
                <input
                  type="email"
                  id="email"
                  name="email"
                  style={{ textTransform: "lowercase" }}
                  required
                  autoComplete="off"
                  placeholder="Email"
                />
              </div>
              <div className="box-of-form-container-of-grevance-form">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  // placeholder="Enter Your Mobile no."
                  required
                  autoComplete="off"
                  placeholder="phone"
                />
              </div>
              <div className="box-of-form-container-of-grevance-form">
                <input
                  type="textarea"
                  id="comment_or_Msg"
                  name="comment_or_Msg"
                  required
                  autoComplete="off"
                  placeholder="Message"
                />
              </div>
              <input type="hidden" name="_captcha" value="false" />
              <input
                type="hidden"
                name="_next"
                value="https://www.brajeshfrombr06.netlify.app"
              />{" "}
              <br />
              <br />
              {/* upper line for By default, after submitting a form the user is shown the FormSubmit "Thank You" page. You can provide an alternative URL for "Thank You" page. */}
              <div className="btn-of-submit-container">
                <div className="container-button">
            
                  <button type="submit" className="submit-btn-of-contact-us-form"><span>Submit</span>
                  <div className="liquid"></div></button>
                </div>
              </div>
            </form>
            <br />
            <br /> <br />
            <br />
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
export default Contactus;
