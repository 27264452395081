
import './App.css';
import Home from './Component/Home/Home';
import { NavLink, Route, Routes } from 'react-router-dom';
import Nav from './Component/Nav/Nav';
import Footer from './Component/Footer/Footer';
import Aboutus from './Component/AboutUs/Aboutus';
import Contactus from './Component/ContactUs/Contactus';

// clickhacking
import { Helmet } from 'react-helmet';
import SupportForm from './Component/Support Form/SupportForm';
import Signup from './Component/Signup/Signup';
import PrivateComp from './Component/PrivateComp';
import Login from './Component/Login/Login';
// import AddBlog from './Component/Blogs/Add Blog/AddBlog';
import AdminPanel from './Component/AdminPanel/AdminPanel';
import NewNav from './Component/Nav/NewNav';
// import BlogFrontend from './Component/Blogs/Show BLog/BlogFrontend';
function App() {
  return (
    <div className="App">

<Helmet>
        <meta http-equiv="X-Frame-Options" content="DENY" />
      </Helmet>

      <Nav/>

      {/* <NewNav/> */}
      <Routes>
        <Route path="/" element={<Home/>}  />
        <Route path="/about-us" element={<Aboutus/>}  />
        <Route path="/contact-us" element={<Contactus/>}  />
        <Route path="/support-form" element={<SupportForm/>}  />
        <Route path="/signup" element={<Signup/>}  />
        <Route path="/login" element={<Login/>}  />
        {/* <Route path="/blogs" element={<BlogFrontend/>}  /> */}



        <Route element={<PrivateComp/>}  >
          {/* <Route path='/add-blog' element={<AddBlog/>} /> */}
          <Route path='/admin-panel' element={<AdminPanel/>} />

      {/* create route which should show on after login */}
          </Route>



      </Routes>
     <Footer/>
    </div>
  );
}

export default App;
