import React, {useEffect} from 'react'
import './Footer.css'
import './ResponsiveFooter.css'
import logoo from '../../unnamed.png'
// import Map from './map-design.png'
import Map from '../Images/map.png'
import {  NavLink,useNavigate } from 'react-router-dom'
// import GoToTopButton from '../Go to Top/GoToTop'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube,FaLinkedinIn } from "react-icons/fa";

// import avcom from '../AvcomNetworkTechnologies/Avcom-Profile.pdf'

function Footer() {

    const auth= localStorage.getItem('user')
const navigate =useNavigate();
const logout=()=>{
    localStorage.clear();
    navigate('/signup')
}
    return (
        <div className="footer-comp">
            <div className='footer'>
                <div className="footer-container">
                    <div className="footer-sec footer-sec-1">

                        <div className='bottom-footer-third-section'>
                            <p><NavLink to='/contactUs' className="locate-us-heading footer-contact-us-dpsru">Contact Us </NavLink></p>
                        </div>
                        <h4 className="footer-short-heading">
                            Office Address :
                        </h4>
                        <p className="footer-text-sec-p">
                           Shiv mandir marg,<br /> Badli, near pancham sweets <br /> 110017, India
                        </p>
                        <h4 className="footer-short-heading">
                            Contact
                        </h4>
                        <p className="footer-text-sec-p">
                        +91 98105 26350, <br/> +91 99111 89318 </p>
                    </div>
                    <div className="footer-sec footer-sec-middle">
                        <img src={logoo} alt="dpsru" />

                    </div>
                    <div className="footer-sec map-footer-sec-new">

                    <div className='bottom-footer-third-section' style={{justifyContent:'center'}}>

                            <p><a href='https://www.google.com/maps/place/Avcom+Network+Technologies/@28.7326418,77.1396856,19z/data=!3m1!4b1!4m6!3m5!1s0x390d06b31149c667:0xd1d4e2868f66e6dc!8m2!3d28.7326406!4d77.1403293!16s%2Fg%2F11b7lbyx9n?entry=ttu' className="locate-us-heading footer-contact-us-dpsru" >Locate Us</a></p>
                        </div>

                   

                        <a href="https://www.google.com/maps/place/Avcom+Network+Technologies/@28.7326418,77.1396856,19z/data=!3m1!4b1!4m6!3m5!1s0x390d06b31149c667:0xd1d4e2868f66e6dc!8m2!3d28.7326406!4d77.1403293!16s%2Fg%2F11b7lbyx9n?entry=ttu" target='_blank'  rel="noreferrer">

                            <img src={Map} alt="Map" />
                        </a>

                    </div>
                </div>

                <div className=" dpsru-text-in-footer"><h1>Avcom Network Technologies</h1></div>

                {/* Bottom Footer */}
                <div className="bottom-footer">
                    <div className="footer-content-dpsru" >
                        <p className="copyright-dpsru">Copyright © 2024 . All rights reserved.</p>

                        {/* <div className='dpsru-social-media-icon'>
                            <a href="https://www.facebook.com/" className='footer-facebook-icon'><FaFacebookF /></a>

                            <a href="https://www.instagram.com/" className='footer-instagram-icon'><FaInstagram /></a>

                            <a href="https://twitter.com/" className='footer-twitter-icon'> <FaTwitter /> </a>

                            <a href="https://www.linkedin.com/" className='footer-linkedin-icon'> <FaLinkedinIn /> </a>

                            <a href='https://www.youtube.com/' className='footer-youtube-icon'><FaYoutube /> </a>
                        </div> */}

                        <div className='bottom-footer-third-section'>



                            <p>
                                { auth? <NavLink onClick={logout} to='/login' >Logout</NavLink>:
                                <NavLink to='/login'>Login</NavLink>
                                
}
                                </p>




                            {/* <p><NavLink to='/admin/admin-login' >Admin</NavLink></p> */}
                            <p><NavLink to='/contact-us'>Contact</NavLink></p>
                            <p><a href='https://brajesh.tech'>Developer</a></p>
                            {/* <p className='go-to-top-button-footer'> <GoToTopButton /></p> */}
                        </div>

                    </div>

                </div>
            </div>

            <div className="avcom-details-in-footer"> <a href='https://brajesh.tech'> brajesh.tech </a> </div>
        </div>

    )
}

export default Footer