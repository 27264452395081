import React from 'react'
import './SupportForm.css'
import supportPic from '../Images/Support-1.png'

const SupportForm = () => {
  return (
    <div className='support-avcom-page'>
      <div className="support-home-page-first-container">
        <div className="support-home-page-text-container">
           <h1 className="support-home-heading">
            Avcom Network Technologies
           </h1>
           <p className='support-home-text-para'>
            Out team works closely with customers to understand their requirements and provide prompt, effective and lasting solutions. This has resulted into Avcom receiving repeat orders in large numbers...
           </p>
        </div>
        <div className="support-home-page-image-container">
           <img src={supportPic} alt="avcom" />
        </div>
      </div>

      <div className="form-about-support-page-avcom">
          <div className=" form-about-support-page-avcom-inner">
            <form
              action="https://formsubmit.co/avcomnetworktechnologies@gmail.com"
              method="POST"
              className="form-container-of-avcom"
            > 
              <h2 className=" form-heading-of-query-ant">Query Form</h2>
              <div className="box-of-form-container-of-grevance-form">
                <input
                  type="text"
                  id="name"
                  name="name"
                  // placeholder="Enter Your Name"
                  required
                  autoComplete="off"
                  placeholder="Name"
                />
              </div>
              <div
                className="box-of-form-container-of-grevance-form"
                style={{ textTransform: "lowercase" }}
              >
                <input
                  type="email"
                  id="email"
                  name="email"
                  style={{ textTransform: "lowercase" }}
                  required
                  autoComplete="off"
                  placeholder="Email"
                />
              </div>
              <div className="box-of-form-container-of-grevance-form">
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  // placeholder="Enter Your Mobile no."
                  required
                  autoComplete="off"
                  placeholder="phone"
                />
              </div>
              <div
                className="box-of-form-container-of-grevance-form"
                style={{ textTransform: "lowercase" }}
              >
                <input
                  type="text"
                  id="designation"
                  name="designation"
                  style={{ textTransform: "lowercase" }}
                  required
                  autoComplete="off"
                  placeholder="Your Designation"
                />
              </div>
              <div
                className="box-of-form-container-of-grevance-form"
                style={{ textTransform: "lowercase" }}
              >
                <input
                  type="text"
                  id="department"
                  name="department"
                  style={{ textTransform: "lowercase" }}
                  required
                  autoComplete="off"
                  placeholder="Department"
                />
              </div>
              
              <div className="box-of-form-container-of-grevance-form">
                <input
                  type="text"
                  id="query"
                  name="query"
                  // placeholder="Enter Your Mobile no."
                  required
                  autoComplete="off"
                  placeholder=" your Query here"
                />
              </div>
              <div className="box-of-form-container-of-grevance-form">
                <input
                  type="textarea"
                  id="comment_or_Msg"
                  name="comment_or_Msg"
                  required
                  autoComplete="off"
                  placeholder="Message"
                />
              </div>
              <input type="hidden" name="_captcha" value="false" />
              <input
                type="hidden"
                name="_next"
                value="https://brajeshfrombr06.netlify.app"
              />{" "}
              <br />
              <br />
              {/* upper line for By default, after submitting a form the user is shown the FormSubmit "Thank You" page. You can provide an alternative URL for "Thank You" page. */}
              <div className="btn-of-submit-container">
                <div className="container-button">
            
                  <button type="submit" className="submit-btn-of-contact-us-form"><span>Submit</span>
                  <div className="liquid"></div></button>
                </div>
              </div>
            </form>
            <br />
            <br /> <br />
            <br />
          </div>
        </div>
      </div>
  
  )
}

export default SupportForm
